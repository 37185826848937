import { createStore } from 'vuex'
import localCache from '@/global/cache'
const store = createStore({
  state: () => {
    return {
      key_name: 'SHITOU',
      certficate:{
        id:null
      }
    }
  },
  mutations: {
    saveCertficate(state, item:any) {
      state.certficate = item
      localCache.setCache("certficate",item)
    },
    getCertficate(state){
      state.certficate = localCache.getCache("certficate")
    }
  },
  getters: {},
  actions: {}
})
export default store
