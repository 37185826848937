import { createApp } from 'vue'
import { registerApp } from './global'
// import './service/axios_demo'
// import myRequest from './service'
import 'element-plus/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 2. 自己项目的重置样式和公用样式
// import '../src/assets/styles/variables.less'
// import '../src/assets/styles/mixins.less'

// import '../src/assets/styles/common.less'

// import * as ElIcons from '@element-plus/icons-vue'
const app = createApp(App)
registerApp(app)

app.use(router)
app.use(store)
app.use(ElementPlus)
app.mount('#app')
document.title = '美课优学'
// myRequest.request({
//   url: '/home/multidata',
//   method: 'GET'
// })
