import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
//大赛
const megagame = () => import('@/views/home/megagame/megagame.vue')
const megagameMsg = () => import('@/views/home/megagame/megagameMsg.vue')
//课程
const courseContent = () => import('@/views/home/course/courseContent.vue')
const courseList = () => import('@/views/home/course/courseList.vue')

//文章
const article = () => import('@/views/home/article/articleList.vue')
const articleContent = () => import('@/views/home/article/articleContent.vue')
const page = () => import('@/views/home/page/page.vue')
const credentials = () => import('@/views/home/credentials/credentials.vue')
const course = () => import('@/views/home/course/course.vue')
const trainManage = () => import('@/views/home/trainManage/trainManage.vue')
const trainMsg = () => import('@/views/home/trainManage/trainMsg.vue')
const expert = () => import('@/views/home/expert/expert.vue')
const involvement = () => import('@/views/home/involvement/involvement.vue')
const shopList = () => import('@/views/home/shop/shopList.vue')
const shopDetail = () => import('@/views/home/shop/shopDetail.vue')

const answer = () => import('@/views/home/answer/answer.vue')
const answerResult = () => import('@/views/home/answer/answerResult.vue')
const examination = () => import('@/views/home/answer/examination.vue')
const allAnswer = () => import('@/views/home/answer/allAnswer.vue')

const certificate = () => import('@/views/home/certificate/certificate.vue')

const shoppingCart = () => import('@/views/home/shop/shoppingCart.vue')
const agreement = () => import('@/views/home/agreement/agreement.vue')
const personalCenter = () => import('@/views/home/personalCenter/personalCenter.vue')
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/login',
    meta: { title: '登录' }
  },
  {
    path: '/home',
    component: () => import('@/views/home/home.vue'),
    children: [
      {
        path: 'index',
        component: page
      },
      {
        path: 'megagame',
        component: megagame
      },
      {
        path: 'megagameMsg',
        component: megagameMsg
      },
      {
        path: 'courseContent',
        component: courseContent
      },
      {
        path: 'course',
        component: course
      },
      {
        path: 'courseList',
        component: courseList
      },
      {
        path: 'article',
        component: article,
        meta: {
          keepAlive: false, //此组件不需要被缓存
          isBack: false //用于判断上一个页面是哪个
        }
      },
      {
        path: 'articleContent',
        component: articleContent
      },
      {
        path: 'credentials',
        component: credentials
      },
      {
        path: 'trainManage',
        component: trainManage
      },
      {
        path: 'trainMsg',
        component: trainMsg
      },
      {
        path: 'expert',
        component: expert
      },
      {
        path: 'involvement',
        component: involvement
      },
      {
        path: 'shopList',
        component: shopList
      },
      {
        path: 'shoppingCart',
        component: shoppingCart
      },
      {
        path: 'shopDetail',
        component: shopDetail
      },
      {
        path: 'answer',
        component: answer
      },
      {
        path: 'answerResult',
        component: answerResult
      },
      {
        path: 'examination',
        component: examination
      },
      {
        path: 'allAnswer',
        component: allAnswer
      },
      {
        path: 'certificate',
        component: certificate
      },
      {
        path:'personalCenter',
        component: personalCenter
      }
    ],
    meta: { title: '美课优学' }
  },
  {
    path: '/agreement',
    component: agreement,
    meta: { title: '协议' }
  },
  {
    path: '/login',
    component: () => import('@/views/login/login.vue'),
    meta: { title: '美课优学' }
  },
  {
    path: '/main',
    component: () => import('@/views/main/main.vue'),
    meta: { title: '美课优学' }
  }
]

const router = createRouter({
  routes,
  history: createWebHashHistory()
})
export default router
