import 'element-plus/theme-chalk/base.css'
import 'element-plus/theme-chalk/index.css'
import {
  ElButton,
  ElInput,
  ElMenu,
  ElSubMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElBreadcrumb,
  ElBreadcrumbItem
} from 'element-plus/lib/components'
import { App } from 'vue'
const components = [
  ElButton,
  ElInput,
  ElMenu,
  ElSubMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElBreadcrumb,
  ElBreadcrumbItem
]
export function registerApp(app: App): void {
  for (const component of components) {
    app.component(component.name, component)
  }
}
