
import { defineComponent } from 'vue'
// import localCache from '@/global/cache'
export default defineComponent({
  name: 'App',
  components: {},
  setup() {
    // let userAgent = navigator.userAgent
    // let is_fireFox = userAgent.indexOf('Firefox') > -1
    // window.onbeforeunload = () => {
    //   window.onunload = async () => {
    //     let userLoginLogId = localCache.getCache('userInfoMsg').userLoginLogId
    //     localCache.deleteCache('userInfoMsg')
    //     return navigator.sendBeacon(
    //       '/admin/logout&userLoginLogId=' + userLoginLogId
    //     )
    //   }
    //   // 因为火狐浏览器关闭浏览器只执行onbeforeunload关闭浏览器
    //   if (is_fireFox) {
    //     let userLoginLogId = localCache.getCache('userInfoMsg').userLoginLogId
    //     //浏览器关闭
    //     localCache.deleteCache('userInfoMsg')
    //     return navigator.sendBeacon(
    //       '/admin/logout&userLoginLogId=' + userLoginLogId
    //     )
    //   }
    // }
  }
})
